body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
  max-width: 750px;
  margin: 0 auto;
  background-color: #f5f5f5
}

.header {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  right: 0;
  min-height: 59px;
}

.body {
  min-height: 100vh;
  padding-top: 59px;
  /* padding-bottom: 56px; */
  box-sizing: border-box;
}

.footer {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 56px
}




.searchInput {}

.searchInput::placeholder {
  color: #ffffff94;
}

searchInput2 {}

.searchInput2::placeholder {
  color: #999;
}

.searchInput:focus {
  outline: none;
}

.searchInput2:focus {
  outline: none;
}

.LanMu {
  color: #ffffffa8;
}

.LanMu .Now {
  color: #FFF;
}

.swiper-container {
  width: 100%
}

.slide-content {}

.slide-content img {
  width: 100%
}